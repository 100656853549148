.attendance {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.attendance .circle {
  border: 2px solid #dfe3f6;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 2px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.attendance .circle-yellow {
  border: 2px solid #fbb500;
}
.attendance .blue-add {
  border: 2px solid blue;
}

.attendance .circle-green {
  border: 2px solid #28d94f;
}
.attendance .default-color {
  border: 2px solid #dfe3f6;
}
.attendance .not-equal {
  border: 2px solid #fff;
}

.attendance .circle-darkgray {
  border: 2px solid #313131;
}

.attendance .circle-red {
  border: 2px solid #fc0707;
}

.attendance .circle-blue {
  border: 2px solid #3d68ff;
}

.attendance .circle[data-title] {
  position: relative;
}

.attendance .circle[data-title]:hover::after,
.attendance .circle[data-title]:hover::before {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

.attendance .circle[data-title]:after {
  content: attr(data-title);
  background-color: #3d68ff;
  color: #fff;
  font-size: 12px;
  border-radius: 8px;
  padding: 5px;
  position: absolute;
  bottom: -30px;
  right: 50%;
  transform: translateX(50%);
  white-space: nowrap;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}

.attendance .circle[data-title]:before {
  content: "";
  border: 10px solid transparent;
  border-top: 0;
  border-bottom: 15px solid #3d68ff;
  position: absolute;
  top: 20px;
  right: 50%;
  transform: translateX(50%);
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}

.attendance .circle svg {
  height: auto !important;
}

.attendance .circle-yellow svg g {
  fill: #fbb500;
}

.attendance .circle-darkgray svg g {
  fill: #313131;
}

.attendance .circle-red svg g {
  fill: #fc0707;
}
