.loader {
  border-radius: 12px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.4);
}

.loader .circle {
  width: 40px;
  height: 40px;
  border: 3px solid #3d68ff;
  border-top-color: transparent;
  animation-name: anim;
  animation-iteration-count: infinite;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  border-radius: 50%;
}

@keyframes anim {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
