.custom-input {
  display: flex;
  align-items: center;
  box-shadow: 0 1px 2px 0 #00000008;
  background: white;
  border-radius: 12px;
}
.select-label {
  color: #5e5873;
  font-size: 15px;
  padding: 8.5px 0 8.5px 15px;
}
.chevron {
  transition: 0.2s ease;
  margin-left: 20px;
}

.drop-down-header {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 23px; */
  letter-spacing: 0;
  text-align: left;
  padding: 0 15px 0 15px;
  display: flex;
  align-items: center;
}

.drop-down-content {
  display: flex;
  align-items: center;
}

.close {
  margin-left: 8px;
}
