.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 20px;
}

.header .titleright {
  display: flex;
  align-items: center;
}

.header .titleright div:first-child {
  margin-right: 20px;
}

@media only screen and (max-width: 576px) {
  .header {
    flex-direction: column !important;
  }
  .header .titleright {
    margin-top: 10px;
  }
}

.header .selects {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
}

.header .selects .select-item {
  margin-left: 25px;
  margin-bottom: 15px;
}
