@font-face {
  font-family: Montserrat;
  src: url(styles/fonts/Montserrat-Regular.ttf);
}

body {
  margin: 0;
  font-family: Montserrat, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f6f9;
}

code {
  font-family: Montserrat source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

::-webkit-scrollbar {
  width: 5px !important;
  cursor: pointer;
}
::-webkit-scrollbar-track {
  background: #edf1ff;
}
::-webkit-scrollbar-thumb {
  background: #3d68ff;
  border-radius: 10px !important;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:first-child) {
  border-top: 2px solid currentColor;
}
