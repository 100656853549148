.table-styles {
  position: relative;
}

.scroll-table {
  margin-bottom: 15px;
  position: relative;
  overflow-x: auto;
  background-color: #fff;
  min-height: 400px;
}

.scroll-table::-webkit-scrollbar {
  height: 5px !important;
}

.scroll-table::-webkit-scrollbar-track {
  transition: all 0.3s;
}

.scroll-table::-webkit-scrollbar-thumb {
  cursor: pointer !important;
}
.scroll-table::-webkit-scrollbar-thumb:hover {
  cursor: pointer !important;
}

.tables {
  width: 100%;
  background-color: #fff;
  border-radius: 12px;
}

thead tr {
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.06) !important;
}

thead tr td:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 9;
  background-color: #fff;
  opacity: 1;
}

thead tr td:nth-child(2) {
  /* position: -webkit-sticky;
  position: sticky;
  left: 35px;
  z-index: 9;
  background-color: #fff;
  opacity: 1; */
  min-width: 200px;
}
@media only screen and (min-width: 576px) {
  thead tr td:nth-child(2) {
    position: -webkit-sticky;
    position: sticky;
    left: 35px;
    z-index: 9;
    background-color: #fff;
    opacity: 1;
    min-width: 250px;
  }
}

thead tr td:nth-child(3) {
  min-width: 150px;
}

tbody {
  position: relative;
  border-top: none !important;
}

tbody tr:last-child {
  border-bottom: none;
}

tbody tr:last-child td {
  border-bottom-width: 0 !important;
}

tbody tr {
  border: 1px solid #dfe3f6;
  border-left: none;
  border-right: none;
}

tbody tr td {
  border-bottom-width: 0 !important;
}

tbody tr td:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 9;
  background-color: #fff;
  opacity: 1;
}

/* tbody tr td:nth-child(2) {
  position: -webkit-sticky;
  position: sticky;
  left: 35px;
  z-index: 9;
  background-color: #fff;
  opacity: 1;
} */

@media only screen and (min-width: 576px) {
  tbody tr td:nth-child(2) {
    position: -webkit-sticky;
    position: sticky;
    left: 35px;
    z-index: 9;
    background-color: #fff;
    opacity: 1;
  }
}

.course-date-style {
  text-align: center;
  margin-bottom: 0;
}

.state-button {
  background: #890fe9;
  border-radius: 16px;
  padding: 0 7px 1px 8px;
  text-align: center;
  font-weight: 500;
  font-size: 13.5px;
  border: none;
  color: #fff;
  margin-left: 20px;
  /* width: 60px; */
}

.loader-text {
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}
